

@import url('https://fonts.googleapis.com/css2?family=Lora:ital,wght@0,400..700;1,400..700&display=swap');
.App {
  text-align: center;

  overflow-x: hidden;
  text-align: center;

  font-family: "Lora", serif;
}


/* * {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
} */

.MuiTypography-root {font-family: "Lora", serif !important;}
a {
  color: inherit;
  text-decoration: none;


}

