.button1 {
  /* linear-gradient(to right,#f9897d 0%,#fd5f87 100%); */
  border-radius: 3px !important;
  padding: 8px 30px !important;
  color: white !important;
  /* background-color: #BD0F65; */
}

.slick-slide > div {
  margin: 0 8px;
}
.slick-list {
  margin: 0 -8px;
}

.slickbuttonprev {
  left: -25px !important;

  top: 40% !important;
  z-index: 1000 !important;
  position: absolute !important;
  margin: auto;
  border-radius: 50% !important;
  background-color: transparent !important;
  display: block;
  position: absolute;
}

.slickbuttonnext {
  display: block;
  position: absolute;
  right: -25px !important;
  top: 40% !important;
  z-index: 1000 !important;
  position: absolute;

  margin: auto;
  border-radius: 50% !important;
  background-color: transparent !important;
}
.large-header {
  position: relative;
  width: 100% !important;
  background: transparent;
  overflow: hidden;
  background-size: cover;
  background-position: center center;
  z-index: 1;

}
.react-parallax-content{width: 100% !important;}




.dropdown {
  position: inherit !important;
  display: inline-block;
  padding-bottom: 25px;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  width: 100vw;
  /* width: 100%; */
  left: 0;
  top: 100%;

  align-items: center;
  padding-top: 50px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
}

.dropdown:hover .dropdown-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  /* max-height: 500px; */
}

.blodcontent{
  font-weight: bolder !important;
color: black !important;
font-size: 1.2rem !important;
}

.blodcontent1{
  font-weight: bolder !important;



}
.contacttext {
  text-align: left !important;
  padding-bottom: 1px !important;
  color: black !important;
  font-size: .8rem;

  margin-block-end: 0 !important;
  margin-block-start: 0px !important;
}